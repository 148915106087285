import * as Paper from "paper";
import { getRandomInt, remapNumbers, getTextColor } from "./util";

function Namat(canvas, drawingArea) {
  Paper.setup(canvas);
  let isFull =
    typeof drawingArea == "string" && drawingArea.toLowerCase() === "full"
      ? 100
      : drawingArea;
  this.drawingArea = this.makeBounds(isFull);
  this.projectBounds = Paper.project.view.bounds;
  this.projectSize = Paper.project.view.size;
}
Namat.prototype.placeText = function (text, center, color) {
  let t = new Paper.PointText(center);
  t.justification = "center";
  t.fillColor = color;
  t.content = text;
  return t;
};
Namat.prototype.makeHalfGrid = function (cols, rows, showIndex) {
  let cellW = this.projectBounds.width / cols;
  let cellH = this.projectBounds.height / rows;
  let gridGroup = new Paper.Group();
  let pt = new Paper.Point(0, 0);
  let index = 0;
  for (let i = 0; i < rows; i++) {
    pt.set(new Paper.Point(0, cellH * i));
    for (let j = 0; j < cols; j++) {
      index++;
      if (j + 1 > cols / 2) {
        //only create cells for half the grid
        let cell = new Paper.Path.Rectangle(pt, new Paper.Size(cellW, cellH));
        gridGroup.addChild(cell);
        if (showIndex) {
          let indexT = this.placeText(j, cell.bounds.center, "red");
          indexT.fontSize = 30;
          gridGroup.addChild(indexT);
        }
        if (index % 2 === 0) {
          cell.name = "red";
        }
        pt.set(cell.bounds.topRight);
      }
    }
  }

  return gridGroup;
};
Namat.prototype.makeGrid = function (cols, rows, showIndex) {
  let gridLayer = new Paper.Layer();
  gridLayer.name = "grid";

  let pView = Paper.project.view;
  let cellW = pView.bounds.width / cols;
  let cellH = pView.bounds.height / rows;

  let someRect = new Paper.Path.Rectangle(
    new Paper.Rectangle(new Paper.Point(0, 0), new Paper.Size(100, 100))
  );
  someRect.fillColor = "red";

  let gridGroup = new Paper.Group();
  let pt = new Paper.Point(0, 0);
  let index = 0;
  for (let i = 0; i < rows; i++) {
    pt.set(new Paper.Point(0, cellH * i));
    for (let j = 0; j < cols; j++) {
      index++;
      let cell = new Paper.Path.Rectangle(pt, new Paper.Size(cellW, cellH));
      gridGroup.addChild(cell);
      if (index % 2 === 0) {
        cell.name = "red";
      }

      if (showIndex) {
        // let indexText = new Paper.PointText(cell.bounds.center);
        // indexText.justification = "center";
        // indexText.fillColor = "yellow";
        // indexText.content = index;
        // let g = this.placeText("doijioj", cell.bounds.center, "black");
        // let colText = indexText.clone();
        // colText.content = "COL: " + j;
        // colText.position.y += 13;
        // let rowText = indexText.clone();
        // rowText.content = "ROW: " + i;
        // rowText.position.y -= 13;
        // gridGroup.addChild(indexText);
        // g.bringToFront();
      }
      pt.set(cell.bounds.topRight);
    }
  }
  gridGroup.bounds.center.set(pView.center);

  return gridGroup;
};
Namat.prototype.makeBounds = function (offset) {
  // offset can be a negative number
  let drawingArea = new Paper.Path.Rectangle(
    new Paper.Point(0, 0),
    new Paper.Size(
      Paper.project.view.size.width - offset,
      Paper.project.view.size.height - offset
    )
  );
  drawingArea.strokeColor = "red";
  drawingArea.strokeWidth = 1;
  drawingArea.bounds.center.set(Paper.project.view.center);
  return drawingArea;
};
Namat.prototype.makeGazu = function ({ columns, rows, bgColor }) {
  let padding = 275;
  let often = columns > 4 ? 3 : 4;
  let cellColor = getTextColor(bgColor);
  let randomPt = new Paper.Point(
    getRandomInt(10, this.projectSize.height),
    getRandomInt(10, this.projectSize.width)
  );
  let gridGroup = this.makeHalfGrid(columns, rows);
  gridGroup.children.map((cell, i) => {
    let rect = cell.clone();
    let distance = cell.bounds.center.getDistance(randomPt);
    let t = remapNumbers(distance, [0, 1080], [0, 40]);
    let n = getRandomInt(0, t);
    if (n < often) {
      rect.style = {
        fillColor: cellColor,
        strokeColor: cellColor,
        strokeCap: "butt",
        strokeJoin: "miter",
      };
    }
    gridGroup.addChild(rect);
    return rect;
  });

  // gridGroup.scale(0.5);
  gridGroup.bounds.rightCenter.set(Paper.project.view.center);
  let mirroredGridGroup = gridGroup.clone();
  mirroredGridGroup.scale(-1, 1, this.projectBounds.center);
  mirroredGridGroup.bounds.leftCenter.set(this.projectBounds.center);
  let gazu = new Paper.Group();
  gazu.addChild(gridGroup);
  gazu.addChild(mirroredGridGroup);
  gazu.bounds.center.set(this.projectBounds.center);
  gazu.bounds.width = this.projectBounds.width - padding;
  gazu.bounds.height = this.projectBounds.height - padding;
  gazu.bounds.center.set(this.projectBounds.center);

  return gazu;
};
Namat.prototype.makeAsymmetricalGazu = function (grid) {
  console.log("making gazu");
  let limit = this.makeBounds(this.drawingArea);
  let randomPt = new Paper.Point(
    getRandomInt(10, Paper.project.view.size.height),
    getRandomInt(10, Paper.project.view.size.width)
  );
  let offset = 0;
  let gridGroup = grid;
  // let gridGroup = this.makeGrid(properties.columns, properties.rows);
  gridGroup.children.map((cell, i) => {
    let rect = new Paper.Path.Rectangle(
      cell.bounds.topLeft,
      new Paper.Size(cell.bounds.width + offset, cell.bounds.height + offset)
    );

    let distance = cell.bounds.center.getDistance(randomPt);
    let t = remapNumbers(distance, [0, 1080], [0, 40]);
    let n = getRandomInt(0, t);
    let isInside = limit.hitTestAll(rect.bounds.center);
    console.log("isindie ", isInside);
    // if (isInside) {
    //   let text = new Paper.PointText(cell.bounds.center);
    //   // rect.fillColor = "blue";
    //   text.content = "0";
    //   text.justification = "center";
    //   text.fillColor = "green";
    // }
    if (n < 3) {
      // rect.fillColor = cellColor;
      // rect.strokeColor = cellColor;
      rect.fillColor = "white";
      rect.strokeColor = "white";
      rect.strokeCap = "butt";
      rect.strokeJoin = "miter";
    }
    gridGroup.addChild(rect);
    return cell;
  });

  gridGroup.bounds.rightCenter.set(Paper.project.view.center);
  gridGroup.scale(0.5);
  // let mirroredGridGroup = gridGroup.clone();
  // mirroredGridGroup.scale(-1, 1, Paper.project.view.bounds.center);
  // mirroredGridGroup.bounds.leftCenter.set(Paper.project.view.bounds.center);
  let gazu = new Paper.Group();
  gazu.addChild(gridGroup);
  gazu.bounds.center.set(Paper.view.center);
  // gazu.addChild(mirroredGridGroup);

  return gazu;
};

export default Namat;
