const Footer = () => {
  return (
    <div
      style={{
        textAlign: "center",
        fontSize: 13,
        // position: "absolute",
        // bottom: 10,
        marginBottom: 20,
      }}
    >
      {"© "}
      {new Date().getFullYear()}
      {" Xakher"}
    </div>
  );
};

export default Footer;
