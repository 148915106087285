import React, { useEffect, useRef, useState } from "react";
import * as Paper from "paper";
import Namat from "../patterns";
import { getRandomElement, getRandomHexColor } from "../util";

import Canvas2Image from "../canvas2image";
const Canvas = () => {
  let canvas = useRef(null);
  const [selectedTier, setSelectedTier] = useState("random");
  const [gridSize, setGridSize] = useState("random");
  // const [key, setKey] = useState(new Date());
  const [properties, setProperties] = useState({
    maxRange: 50,
    size: 10,
    strokeSize: 1,
    modifySize: 5,
    columns: 10,
    rows: 10,
    color: "#000000",
    bgColor: "#ffffff",
  });
  let d = 0;
  const handleDownload = async (requestedType) => {
    let fileName = properties.columns + "x" + properties.rows + "_gazu_" + d;
    Canvas2Image.saveAsImage(canvas, 3000, 3000, "JPG", fileName);
    d++;
  };
  const createGazu = (gSize) => {
    selectedTier === "random"
      ? (gSize = getRandomElement([4, 6, 8, 10, 12, 14, 16, 18, 20]))
      : (gSize = selectedTier.split("x")[0]);
    setGridSize(gSize);
    let namat = new Namat(canvas);
    Paper.project.clear();
    let bgColor = getRandomHexColor();
    let gazuProps = {
      ...properties,
      columns: gSize,
      rows: gSize,
      bgColor,
    };
    setProperties(gazuProps);
    namat.makeGazu(gazuProps);
    let gazu2 = namat.makeGazu(gazuProps);
    let gazu3 = namat.makeGazu(gazuProps);
    gazu2.opacity = 0.55;
    gazu3.opacity = 0.25;

    let backgroundRect = new Paper.Path.Rectangle(
      new Paper.Point(0, 0),
      new Paper.Size(500, 500)
    );
    backgroundRect.fillColor = bgColor;
    backgroundRect.sendToBack();
  };
  useEffect(() => {
    createGazu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      style={{
        display: "flex",
        marginTop: 100,
        marginBottom: 30,
        color: "black",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <canvas
        // onClick={() => setKey(new Date())}
        style={{ width: "100%", height: "auto" }}
        ref={(ref) => (canvas = ref)}
        // key={key}
        width={500}
        height={500}
        data-paper-resize="false"
        data-paper-keepalive="false"
      />
      {/* <input
        type="button"
        value="Download Single"
        onClick={() => handleDownload("JPG")}
      />
      <input
        type="button"
        value="Download 9"
        onClick={() => {
          for (let i = 0; i < 9; i++) {
            setTimeout(() => {
              console.log("this is the first message");
              createGazu();
              // setCount(10);
              handleDownload("JPG");
            }, 1000);
          }
        }}
      /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          maxWidth: 500,
          justifyContent: "center",
          width: "100%",
          // backgroundColor: "red",
          // backgroundColor: "green",
        }}
      >
        <TierButton
          selected={selectedTier}
          label="BUY GAZU"
          price="0.09"
          // mint the nft and sell it here
          handleClick={() => handleDownload("JPG")}
          style={{
            width: "100%",
            marginTop: 20,
            // marginBottom: 20,
          }}
        />
        <TierButton
          selected={selectedTier}
          label="Generate GAZU"
          price="0.03"
          handleClick={() => createGazu(gridSize)}
          style={{
            width: "100%",
            marginTop: 20,
            //  backgroundColor: "green"
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
          maxWidth: 500,
          justifyContent: "center",
          backgroundColor: "white",
          paddingTop: 20,
          marginTop: 20,
          paddingBottom: 10,
        }}
      >
        <div style={{ fontSize: 25 }}>GRID SIZE</div>
        <TierButton
          in
          style={{ width: "85%", marginBottom: 10, marginTop: 10 }}
          selected={selectedTier}
          label="Random"
          price="0.09"
          handleClick={() => setSelectedTier("random")}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <TierButton
            in
            selected={selectedTier}
            label="4x4"
            price="0.4"
            handleClick={() => setSelectedTier("4x4")}
          />
          <TierButton
            in
            selected={selectedTier}
            label="6x6"
            price="0.35"
            handleClick={() => setSelectedTier("6x6")}
          />
          <TierButton
            in
            selected={selectedTier}
            label="8x8"
            price="0.3"
            handleClick={() => setSelectedTier("8x8")}
          />
          <TierButton
            in
            selected={selectedTier}
            label="10x10"
            price="0.25"
            handleClick={() => setSelectedTier("10x10")}
          />
          <TierButton
            in
            selected={selectedTier}
            label="12x12"
            price="0.2"
            handleClick={() => setSelectedTier("12x12")}
          />
          <TierButton
            in
            selected={selectedTier}
            label="14x14"
            price="0.15"
            handleClick={() => setSelectedTier("14x14")}
          />
          <TierButton
            in
            selected={selectedTier}
            label="16x16"
            price="0.1"
            handleClick={() => setSelectedTier("16x16")}
          />
          <TierButton
            in
            selected={selectedTier}
            label="18x18"
            price="0.05"
            handleClick={() => setSelectedTier("18x18")}
          />
          <TierButton
            in
            selected={selectedTier}
            label="20x20"
            price="0.01"
            handleClick={() => setSelectedTier("20x20")}
          />
        </div>
      </div>
      {/* <Selectors
        properties={properties}
        handleUpdateColumns={(e) =>
          setProperties({
            ...properties,
            columns: e.target.value,
          })
        }
        handleUpdateRows={(e) =>
          setProperties({
            ...properties,
            rows: e.target.value,
          })
        }
      /> */}
    </div>
  );
};

const TierButton = (props) => {
  return (
    <div
      onClick={props.handleClick}
      style={{
        cursor: "pointer",
        // width: "50%",
        padding: 20,
        minWidth: 100,
        color: props.in ? "white" : "black",
        backgroundColor:
          props.selected?.toLowerCase() === props.label?.toLowerCase()
            ? "red"
            : props.in
            ? "black"
            : "white",
        border: "none",
        margin: props.in ? 10 : 0,
        ...props.style,
      }}
    >
      {props.label}
      <div>{props.price} ETH</div>
    </div>
  );
};
// const Selectors = (props) => {
//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         width: 500,
//         maxWidth: "100%",
//         // backgroundColor: "green",
//       }}
//     >
//       <label
//         style={{
//           padding: 10,
//           marginTop: 10,
//           backgroundColor: "white",
//           display: "flex",
//           flexDirection: "row",
//           justifyContent: "space-between",
//         }}
//       >
//         Columns {props.properties?.columns}
//         <input
//           type="range"
//           max={30}
//           min={0}
//           value={props.properties?.columns}
//           onChange={props.handleUpdateColumns}
//         />
//       </label>
//       <label
//         style={{
//           padding: 10,
//           marginTop: 10,
//           backgroundColor: "white",
//           display: "flex",
//           flexDirection: "row",
//           justifyContent: "space-between",
//         }}
//       >
//         Rows {props.properties?.rows}
//         <input
//           type="range"
//           max={30}
//           min={0}
//           value={props.properties?.rows}
//           onChange={props.handleUpdateRows}
//         />
//       </label>
//     </div>
//   );
// };

export default Canvas;
