import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Generate from "./pages/generate";
import Home from "./pages/home";
import Footer from "./components/Footer";

function App() {
  return (
    <Router>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "black",
          // backgroundColor: "green",
          // width: "100%",
          // maxWidth: "50%",
        }}
      >
        <Navbar />
        <Switch>
          <Route path="/generate">
            <Generate />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
