import { useState } from "react";
import NFTCanvas from "../components/NFTCanvas";

function Generate() {
  const [password, setPassword] = useState("bushky11");
  const [userInput, setUserInput] = useState("");
  const [access, setAccess] = useState(false);
  const [error, setError] = useState(false);
  return (
    <div
      className="App"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        // height: "100%",
        // backgroundColor: "red",
      }}
    >
      {!access ? (
        <NFTCanvas />
      ) : (
        <form
          action="submt"
          onSubmit={(e) => {
            e.preventDefault();
            setPassword("bushky11");
          }}
        >
          <div
            style={{
              backgroundColor: "black",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ margin: 10, color: "white", fontWeight: 900 }}>
              Enter Password
            </div>
            <input
              onChange={(e) => {
                setError(false);
                setUserInput(e.target.value);
              }}
              type="password"
              style={{
                width: 200,
                padding: 10,
                borderRadius: 10,
                border: "none",
              }}
            />
            <button
              type="submit"
              onClick={() => {
                if (userInput === password) {
                  setAccess(true);
                } else {
                  setError(true);
                }
              }}
              style={{
                padding: 10,
                // width: 200,
                width: "100%",
                borderRadius: 10,
                marginTop: 10,
                color: "white",
                fontWeight: 900,
                backgroundColor: "red",
                border: "none",
                cursor: "pointer",
              }}
            >
              Enter
            </button>
            {error ? (
              <div style={{ color: "red" }}>Password not correct</div>
            ) : null}
          </div>
        </form>
      )}
    </div>
  );
}

export default Generate;
