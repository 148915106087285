// import invadersGif from "../invaders-gif.gif";
import { useWindowSize } from "../reactHooks";
function Home() {
  const screenSize = useWindowSize();
  return (
    <div
      style={{
        marginTop: 150,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // height: "100vh",
        // backgroundColor: "red",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // backgroundColor: "orange",
          textAlign: screenSize.width > 600 ? "left" : "center",
          justifyContent: screenSize.width > 600 ? "space-between" : "center",
          alignItems: "center",
          maxWidth: screenSize.width > 600 ? "70%" : "90%",
          width: "100%",
        }}
      >
        {/* <h1>1,000,000 NFT's</h1> */}
        <div style={{ fontSize: 60, fontWeight: 800 }}>JOIN THE CLUB</div>
        <p>COMING SOON</p>
        {/* <h3>Coming soon to NFT marketplace</h3> */}
      </div>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        <AllGifs />
      </div>
      {/* <div
        style={{
          display: "flex",
          flexDirection: screenSize.width > 600 ? "row" : "column",
          // backgroundColor: "orange",
          textAlign: screenSize.width > 600 ? "left" : "center",
          justifyContent: screenSize.width > 600 ? "space-between" : "center",
          alignItems: "center",
          maxWidth: screenSize.width > 600 ? "70%" : "90%",
          width: "100%",
          margin: 20,
        }}
      > */}
      {/* <div>
          <h1>1,000,000 NFT's</h1>
          <h3>COMING SOON</h3>
          {/* <h3>Coming soon to NFT marketplace</h3> */}
      {/* </div> */}
      {/* <img src={invadersGif} alt="Logo" height="300" /> */}
      {/* </div> */}
      <TierSection
        tierTitle="4x4"
        rarity="Extremely Coveted"
        color="#FF2400"
        remaining={100}
      />
      <TierSection
        tierTitle="6x6"
        rarity="Ultimate"
        color="#FFD580"
        remaining={300}
      />
      <TierSection
        tierTitle="8x8"
        rarity="Legendary"
        color="#FF0000"
        remaining={500}
      />
      {/* jiij */}
      <TierSection
        tierTitle="10x10"
        rarity="Extremely Rare"
        color="#723C7C"
        remaining={700}
      />
      <TierSection
        tierTitle="12x12"
        rarity="Highly Sought"
        color="#8F00FF"
        remaining={900}
      />
      {/* jiij */}
      <TierSection
        tierTitle="14x14"
        rarity="Very Rare"
        color="#0000F5"
        remaining={1100}
      />
      <TierSection
        tierTitle="16x16"
        rarity="Rare"
        color="#ADD8E6"
        remaining={1300}
      />
      <TierSection
        tierTitle="18x18"
        rarity="Uncommon"
        color="#90ee90"
        remaining={1500}
      />
      <TierSection
        tierTitle="20x20"
        rarity="Common"
        color="#D3D3D3"
        remaining={2000}
      />
    </div>
  );
}

const AllGifs = () => {
  return [4, 6, 8, 10, 12, 14, 16, 18, 20].map((gridSize) => {
    return (
      <img
        src={`https://xakher-images.s3.ap-southeast-1.amazonaws.com/GAZU/gifs/${gridSize}x${gridSize}_gif.gif`}
        alt="Logo"
        height="150"
        width="150"
        // style={{ margin: 10 }}
      />
    );
  });
};
// Common (Light Gray)
// Uncommon (Light Green)
// Rare (Light Blue)
// Very Rare (Dark Blue)
// Highly Sought (Violet)
// Extremely Rare (Very Dark Purple)
// Legendary (Red)
// Ultimate (Light Orange)
// Extremely Coveted (Scarlet)

const TierSection = (props) => {
  const screenSize = useWindowSize();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: screenSize.width > 600 ? "row" : "column",
        textAlign: screenSize.width > 600 ? "left" : "center",
        justifyContent: screenSize.width > 600 ? "space-between" : "center",
        alignItems: "center",
        maxWidth: screenSize.width > 600 ? "50%" : "90%",
        width: "100%",
        margin: 100,
        // padding: 100,
        // backgroundColor: "orange",
      }}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            fontSize: 100,
            textAlign: screenSize.width > 600 ? "left" : "center",
          }}
        >
          {props.tierTitle}
        </div>
        <div
          style={{
            color: props.color,
            fontSize: 25,
            // backgroundColor: "white",
            padding: 10,
            // width: "fit-content",
            // textAlign: screenSize.width > 600 ? "left" : "center",
          }}
        >
          {props.rarity}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={`https://xakher-images.s3.ap-southeast-1.amazonaws.com/GAZU/gifs/${props.tierTitle}_gif.gif`}
          alt="Logo"
          height="300"
          width="300"
          style={{ margin: 10 }}
        />
        <div
          style={{
            // backgroundColor: "red"
            justifyContent: "flex-end",
            display: "flex",
            flexWrap: "wrap",
            padding: 10,
            maxWidth: 300,
            textAlign: "center",
          }}
        >
          {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((d) => {
            let fileName = props.tierTitle + "_gazu_" + d;
            console.log("fi ", fileName);
            let imgURL = `https://xakher-images.s3.ap-southeast-1.amazonaws.com/GAZU/club/${fileName}.JPG`;
            return (
              <img src={imgURL} alt="Logo" height="80" style={{ margin: 10 }} />
            );
          })}
        </div>
        <div>{props.remaining} More</div>
      </div>
    </div>
  );
};

export default Home;
