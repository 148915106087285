import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../invaders-logo.svg";
import menuIcon from "../menu_icon.svg";
import { useWindowSize, useOutsideAlerter } from "../reactHooks";
const Navbar = () => {
  const screenSize = useWindowSize();
  const menuRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  useOutsideAlerter(menuRef, () => setMenuOpen(false));
  return (
    <div
      style={{
        width: "100%",
        maxWidth: "70%",
        display: "flex",
        flexDirection: "row",
        padding: 10,
        // marginTop: 10,
        justifyContent: "space-between",
        alignItems: "center",
        color: "white",
        position: "absolute",
        top: 0,
        // backgroundColor: "yellow",
      }}
    >
      <Link to="/">
        <img src={logo} alt="Logo" height="40" />
      </Link>

      {screenSize.width > 600 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link
            style={{ margin: 15, textDecoration: "none", color: "white" }}
            to="/"
          >
            About
          </Link>
          <Link
            style={{ margin: 15, textDecoration: "none", color: "white" }}
            to="/generate"
          >
            Generate
          </Link>
          <a
            href="https://opensea.io/collection/gazu"
            style={{
              margin: 15,
              textDecoration: "none",
              color: "white",
              backgroundColor: "red",
              padding: 5,
              width: 50,
              borderRadius: 10,
              fontWeight: 800,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Buy
          </a>
        </div>
      ) : (
        <div style={{ position: "relative" }}>
          <img
            src={menuIcon}
            alt="icon"
            height="30"
            onClick={() => setMenuOpen(!menuOpen)}
          />
          {menuOpen ? (
            <div
              ref={menuRef}
              style={{
                position: "absolute",
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                color: "black",
                padding: 10,
                right: 0,
              }}
            >
              <Link
                to="/"
                onClick={() => setMenuOpen(!menuOpen)}
                style={{ textDecoration: "none", textAlign: "center" }}
              >
                <div style={{ margin: 15 }}>About</div>
              </Link>
              <Link
                to="/generate"
                onClick={() => setMenuOpen(!menuOpen)}
                style={{ textDecoration: "none", textAlign: "center" }}
              >
                <div style={{ margin: 15 }}>Generate</div>
              </Link>
              <a
                href="https://opensea.io/collection/gazu"
                style={{
                  margin: 15,
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "red",
                  padding: 5,
                  // width: 50,
                  borderRadius: 10,
                  fontWeight: 800,
                  display: "flex",
                  justifyContent: "center",
                  // width: "100%",
                  alignItems: "center",
                }}
              >
                Buy
              </a>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Navbar;
